.logo{
    width: 80px !important;
    height: 80px !important;
    background-size: 100%;
    object-fit: cover;
  }
  /* styles.css */

/* Add styling for the sticky navigation bar */
.sticky-navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: none;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Adjust z-index as needed */
}

/* Style for the navigation items */
.sticky-navbar a {
  text-decoration: none;
  font-size: 25px;
  font-weight: 900;
  color: #345430;
  cursor: pointer;
  font-family: 'Fredoka', sans-serif;
  background-color: transparent;
  padding: 0 10px;
}

/* Style for active navigation item */
.sticky-navbar a.active {
  background-color: #E0EAE0;
}
