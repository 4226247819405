@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400&family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
    font-family: 'Inter', 'League Spartan', sans-serif,  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    font-family: 'DM Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: white !important;
  color: #345430 !important; */
  max-width: 100%; /* Ensure the content doesn't exceed the viewport width */
  margin: 0 auto; /* Center the content horizontally */
}

html {
  font-size: 16px; /* Set a default font size */
}

/* Set a maximum font size for various elements
h1, h2, h3, p {
  max-width: 100%; /* Ensure text doesn't overflow */
  /* font-size: 1.5rem; /* Adjust as needed */
/* } */ 

/* Add more styles for other elements as needed */

.zoom{
  zoom: "70%"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
css-1vgunc4:hover{
  color: #345430 ;
}
.css-53fww0,
.css-7yxbe8{
  width: 100%;
  max-width: 90vw;
}
.flutter .light-mode {
  background-color: #345430;
  color: white;
}

/* Styles for dark mode */
.flutter .dark-mode  {
  background-color: white;
  color: #345430;
}

.flutter{
    background-color: #345430;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.chakra-ui-dark{
  background-color: #345430 !important;
  color: white !important;
}
.swiper-button-prev, .swiper-button-next{
  color: #000  !important;
}
.count{
  font-size: 48px;
  font-weight: 800;
}
.DraftEditor-editorContainer > .public-DraftEditor-content {
  padding: 20px;
  height: 100vh;
}
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  padding: 1rem;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.dropDown{
  color: #fff;
}
.swiper-button-prev, .swiper-button-next {
  color: white !important;
}

/* Apply different colors for light and dark modes */
.chakra-ui-light .swiper-button-prev, .chakra-ui-light .swiper-button-next {
  color: #345430 !important;
}

.chakra-ui-dark .swiper-button-prev, .chakra-ui-dark .swiper-button-next {
  /* You can keep the color white for dark mode if needed */
  color: white !important;
}
.PhoneInputCountryIconImg{
  height: 50px !important;
}
.PhoneInputCountryIcon .PhoneInputCountryIcon--border{
  background-color: white;
}
.logo{
  width: 80px !important;
  height: 80px !important;
  background-size: 100%;
  object-fit: cover;
}
/* styles.css */

/* Add styling for the sticky navigation bar */
.sticky-navbar {
position: fixed;
top: 0;
left: 0;
width: 100%;
background-color: white;
box-shadow: none;
padding: 10px 20px;
display: flex;
justify-content: space-between;
align-items: center;
z-index: 1000; /* Adjust z-index as needed */
}

/* Style for the navigation items */
.sticky-navbar a {
text-decoration: none;
font-size: 25px;
font-weight: 900;
color: #345430;
cursor: pointer;
font-family: 'Fredoka', sans-serif;
background-color: transparent;
padding: 0 10px;
}

/* Style for active navigation item */
.sticky-navbar a.active {
background-color: #E0EAE0;
}
.PhoneInputCountrySelect {
width: 100px;
height: 50px;
border-radius: 10px;
}
.PhoneInputCountry{
display: flex;
justify-content: space-between;
align-items: center;
gap: 20px;
}
.PhoneInputInput{
width: 100%;
border-radius: 10px;
}
/* styles.css or in the component's CSS module */
.article-content-light p span{
  color: #345430 !important;
}

.article-content-dark p span{
  color: white !important;
}
.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.preview-content {
  white-space: pre-wrap; /* Handle whitespace */
}

.preview-content img {
  max-width: 100%;
  width: 300px;
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra space below images */
  margin: 0 auto; /* Center images horizontally */
}
